<template>
    <div class="container">
        <!--UPLOAD-->
        <div class="form-inline">
            <b-col :sm="colsizeDZ" class="mx-0 px-0 my-1 py-0">
                <vue-dropzone
                    ref="myDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="resizePicture"
                    @vdropzone-removed-file="removeFile"
                >
                </vue-dropzone>
            </b-col>
            <b-col
                :sm="colsizeCam"
                class="mx-0 px-0 my-1 py-0"
                v-if="showCamera"
            >
                <camera-picture @picture="addPicture"> </camera-picture>
            </b-col>
        </div>
        <div class="form-inline">
            <b-input-group
                :prepend="strAantUpload"
                :label="$t('com_upload', { ns: 'common' })"
            >
                <b-form-input
                    :placeholder="strAantBestanden"
                    v-model="showUploads"
                    readonly
                ></b-form-input>
            </b-input-group>

            &nbsp; <lookup-label-combo v-if="true" @input="labelSelected" :clear
            ="clear" editAllowed: true /> &nbsp; &nbsp;
            <b-button @click="save" class="bgc_aux_normal"
                ><font-awesome-icon icon="arrow-up" size="1x" />
            </b-button>
            &nbsp; &nbsp;
            <b-button class="bgc_aux_unimportant" @click="doShowCamera">
                <font-awesome-icon icon="camera" size="1x" />
            </b-button>
        </div>
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import { AttachmentInfo } from '@/models/PlusProduction';
import { loadItem } from '../../models/DataHelper';
import CameraPicture from './CameraPicture.vue';
import LookupLabelCombo from './LookupLabelCombo.vue';
import Helper from '@/models/Helper';

export default {
    name: 'FileUpload',
    data: function () {
        return {
            attachmentTypeAnalytics: window.constants.ATT_INVESTIGATIONQUANTITY,
            uploadAantal: 0,
            uploadResized: 0,
            showCamera: false,
            colsizeCam: 8,
            colsizeDZ: 12,
            labelID: 1,
            catID: 1,
            expirationDate: null,
            validFrom: null,
            validTill: null,
            niceName: null,
            clear: 0,
            dropzoneOptions: {
                url: '/file/upload',
                autoProcessQueue: false,
                acceptedFiles:
                    '.jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF,.pdf,.PDF, .txt, .TXT',
                addRemoveLinks: true,
                resizeWidth: 1000,
                maxFilesize: 0.5,
                dictDefaultMessage: 'UPLOAD'
            },
            strAantBestanden:
                this.$t('com_number', { ns: 'common' }) +
                ' ' +
                this.$t('com_files', { ns: 'common' }).toLowerCase(),
            strAantUpload:
                this.$t('com_number', { ns: 'common' }) +
                ' ' +
                this.$t('com_upload', { ns: 'common' }).toLowerCase()
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        attID: {
            type: Number,
            required: false,
            default: -1
        },
        attType: {
            type: Number,
            required: false,
            default: -1
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
        CameraPicture,
        LookupLabelCombo
    },
    watch: {},
    computed: {
        showUploads() {
            return this.uploadResized > 0
                ? Helper.stringformat(
                      '{0} (resized {1})',
                      this.uploadAantal,
                      this.uploadResized
                  )
                : this.uploadAantal;
        }
    },
    methods: {
        doShowCamera() {
            this.showCamera = !this.showCamera;
            this.colsizeDZ = this.showCamera ? 4 : 12;
        },
        labelSelected(value) {
            this.expirationDate = value.expirationDate;
            this.validFrom = value.validFrom;
            this.validTill = value.validTill;
            this.niceName = value.niceName;
            this.labelID = value.label;
        },
        async save() {
            // upload data to the server
            const myDZ = this.$refs.myDropzone;
            const lijst = myDZ.getQueuedFiles();
            for (let i = 0; i < lijst.length; i++) {
                const att = new AttachmentInfo();
                att.id = this.attID;
                att.formdata = lijst[i];
                att.attachmentType = this.attType;
                att.verloopdatum = this.expirationDate;
                att.van = this.validFrom;
                att.tot = this.validTill;
                att.display = this.niceName;
                att.labelID = this.labelID;
                const a = att.SetForSave();
                if (a != null) {
                    if (att.attachmentType > 0) {
                        if (
                            att.attachmentType >= this.attachmentTypeAnalytics
                        ) {
                            await loadItem(
                                'analytics/saveAttachment',
                                a,
                                this.$t
                            );
                        } else {
                            await loadItem(
                                'components/saveAttachment',
                                a,
                                this.$t
                            );
                        }
                    } else {
                        await loadItem(
                            'tienplus/saveSimpleProductionAttachment',
                            a,
                            this.$t
                        );
                    }
                }
            }
            this.reset();
            this.$emit('input', true);
        },
        reset() {
            this.clear += 1;
            this.uploadAantal = 0;
            this.uploadResized = 0;
            const myDZ = this.$refs.myDropzone;
            myDZ.removeAllFiles();
            this.$emit('queue', this.uploadAantal);
        },
        setCounters(aant, resized = 0) {
            this.uploadAantal += aant;
            this.uploadResized += resized;
            this.$emit('queue', this.uploadAantal);
        },
        removeFile() {
            this.setCounters(-1);
        },
        resizePicture(origFile) {
            const file = origFile;
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            const validOtherTypes = ['text/plain', 'application/pdf'];
            if (validOtherTypes.includes(fileType)) {
                const isGoed = origFile.size < window.constants.MAX_FILESIZE;
                if (isGoed) this.uploadAantal += 1;
            }
            if (validImageTypes.includes(fileType)) {
                const MAX_HEIGHT = window.constants.MAX_IMAGE_HEIGHT ; //480;
                const myDZ = this.$refs.myDropzone.dropzone;
                const isGoed = origFile.size < window.constants.MAX_FILESIZE;
                const self = this;
                const reader = new FileReader();
                let doAdd = isGoed;

                // Convert file to img
                reader.addEventListener('load', function (event) {
                    const origImg = new Image();
                    origImg.src = event.target.result;

                    origImg.addEventListener('load', function (event) {
                        let width = event.target.width;
                        let height = event.target.height;
                        const resizefactor =
                            height > MAX_HEIGHT ? MAX_HEIGHT / height : 1;
                        const MAX_WIDTH = width * resizefactor;
                        if (resizefactor >= 1) return;

                        // Don't resize if it's small enough
                        if (width <= MAX_WIDTH && height <= MAX_HEIGHT) {
                            //myDZ.enqueueFile(origFile);
                            return;
                        }

                        // Calc new dims otherwise

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        // Resize
                        const canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(origImg, 0, 0, width, height);

                        const resizedFile = self.base64ToFile(
                            canvas.toDataURL('image/jpeg'),
                            origFile
                        );
                        doAdd = resizedFile.accepted;
                        // Replace original with resized

                        const origFileIndex = myDZ.files.indexOf(origFile);
                        myDZ.files[origFileIndex] = resizedFile;

                        if (doAdd) {
                            self.setCounters(1, 1);
                        }
                    });
                });
                if (isGoed) {
                    self.setCounters(1);
                }
                reader.readAsDataURL(origFile);
            }
        },
        base64ToFile(dataURI, origFile) {
            let byteString;

            if (dataURI.split(',')[0].indexOf('base64') !== -1) {
                byteString = atob(dataURI.split(',')[1]);
            } else {
                byteString = decodeURI(dataURI.split(',')[1]);
            }

            const mimestring = dataURI
                .split(',')[0]
                .split(':')[1]
                .split(';')[0];

            const content = [];
            for (let i = 0; i < byteString.length; i++) {
                content[i] = byteString.charCodeAt(i);
            }

            const newFile = new File([new Uint8Array(content)], origFile.name, {
                type: mimestring
            });

            // Copy props set by the dropzone in the original file

            const origProps = [
                'upload',
                'status',
                'previewElement',
                'previewTemplate',
                'accepted'
            ];

            origProps.forEach(function (p) {
                newFile[p] = origFile[p];
            });
            newFile.status = 'queued';
            newFile.accepted =
                newFile.size < window.constants.MAX_FILESIZE ? 'true' : 'false';

            return newFile;
        },
        addPicture(options) {
            const myDZ = this.$refs.myDropzone;
            const file = options.file;
            file.status = 'queued';
            file.accepted =
                file.size < window.constants.MAX_FILESIZE ? 'true' : 'false';
            if (file.accepted) this.uploadAantal += 1;
            myDZ.manuallyAddFile(file, options.url);
            this.doShowCamera();
        }
    },
    mounted() {}
};
</script>
<style scoped></style>
